import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

	{
		path: '/pc',
		name: 'main',
		component: () => import('../views/main.vue'),
		children: [
			{ //首页
				path: '/', //默认为首页显示
				name: 'first',
				component: () => import('@/views/layout/first.vue')
			},

			{
				path: '/information',
				name: 'information',
				component: () => import('@/views/layout/information.vue')
			},

			{
				path: '/search',
				name: 'search',
				component: () => import('@/views/layout/search.vue')
			},

			{
				path: '/observation',
				name: 'observation',
				component: () => import('@/views/layout/observation.vue')
			},
			{
				path: '/case',
				name: 'case',
				component: () => import('@/views/layout/case.vue')
			},
			{
				path: '/works',
				name: 'works',
				component: () => import('@/views/layout/works.vue')
			},
			{
				path: '/connection',
				name: 'connection',
				component: () => import('@/views/layout/connection.vue')
			},
			{
				path: '/introduction',
				name: 'introduction',
				component: () => import('@/views/layout/introduction.vue')
			},
			{
				path: '/standard',
				name: 'standard',
				component: () => import('@/views/layout/standard.vue')
			}
		]
	},
	{
		path: '/move',
		name: 'move',
		component: () => import('../views/move.vue'),
		children: [

			{ //首页
				path: '/', //默认为首页显示
				name: 'mhome',
				component: () => import('@/views/movepage/Mhome.vue')
			},

			{
				path: '/mdetails',
				name: 'mdetails',
				component: () => import('@/views/movepage/Mdetails.vue')
			},
			{
				path: '/msearch',
				name: 'msearch',
				component: () => import('@/views/movepage/Msearch.vue')
			},
			{
				path: '/mtemplate',
				name: 'mtemplate',
				component: () => import('@/views/movepage/Mtemplate.vue')
			}
		]
	},

	{
		path: '/header',
		name: 'Header',
		component: () => import('@/components/Header/index.vue')
	},

	{
		path: '/footer',
		name: 'footer',
		component: () => import('@/components/Footer/index.vue')
	},

]
const router = new VueRouter({
	mode: 'history',
	routes

})

export default router
