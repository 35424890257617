import Vue from 'vue'
import App from './App.vue'
import router from './router'
import element from 'element-ui'
import axios from 'axios'
import BaiduMap from 'vue-baidu-map'
import {getAxioss, postAxioss} from '@/request/http.js'

Vue.config.productionTip = false
Vue.prototype.$get = getAxioss
Vue.prototype.$post = postAxioss
Vue.prototype.$axios = axios
Vue.use(element)
// Vue.use(BaiduMap, {
//     ak: 'http://api.map.baidu.com/api?v=2.0&ak=oN3zTOzIQCgCWicg1GxvjwWGNXRR6v8a'
// })
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
