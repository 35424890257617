<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		mounted() {
			if (this._isMobile()) {
				this.$router.replace('/move');
			} else {
				this.$router.replace('/pc');
			}
		},
		methods: {
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
					)
				return flag;
			}
		}
	}
</script>

<style src="@/assets/css/global.css">
	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		margin-top: 60px;
	}
</style>
