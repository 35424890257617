import axios from 'axios'
import router from '../router'
import {Message} from 'element-ui';

const axioss = axios.create({
    baseURL: "https://service.cciaimm.org.cn/"
    // baseURL: "http://127.0.0.1:8082"

})

axioss.interceptors.request.use(
    config => {
        config.headers = {
            'Content-Type': 'application/json',
            'token': '211'
        };
        if (config.method === 'post') {
            config.data = JSON.stringify(config.data);
        }
        return config;
    },
    erro => {
        return Promise.error(erro);
    }
)
axioss.interceptors.response.use(
    response => {
        if (response.data.code === 1) {
            return response.data;
        } else if (response.data.code === 101) {
            router.push("/login");
        } else {
            Message(response.data.msg);
        }
    },
    erro => {
        console.log(erro.response);
        switch (erro.response.status) {
            case 500:
                Message('这是服务器错误，请联系管理员');
                break;
            case 404:
                Message('路径错误');
                break;
            case 403:
                Message('这是服务器错误，请联系管理员');
                break;
            default:
                Message('这是服务器错误，请联系管理员');
        }
    })

export function getAxioss(url, param) {
    return axioss({
        url: url,
        params: param,
        method: 'get'
    }).then(data => {
        console.log(data);
        return data;
    })
}


export function postAxioss(url, param) {
    return axioss.post(url, param).then(item => {
        return item;
    })
}
